import { inject, reactive } from "vue";
import { useRoute } from "vue-router";
import { store } from "../store";
import Segments from "./Segments";
import * as SmartySDK from "smartystreets-javascript-sdk";

const SmartyCore = SmartySDK.core;
const websiteKey = "115379242330163169";
const smartySharedCredentials = new SmartyCore.SharedCredentials(websiteKey);
const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(
  smartySharedCredentials
);
const autoCompleteClient = autoCompleteClientBuilder.buildUsAutocompleteProClient();
const { OrderCompleted } = Segments();

export default function Account(flagFrom) {
  // let fileUrl;
  const axios = inject("axios");

  const orders = reactive({
    loading: false,
    data: [],
    error: "",
  });

  const reOrderdetails = reactive({
    loading: false,
    data: [],
    error: "",
  });

  const users = reactive({
    loading: false,
    data: [],
    activeUser: "",
    error: "",
  });

  const address = reactive({
    loading: false,
    data: [],
    error: "",
    address_id: "",
    licenses: [],
  });
  const addresses = reactive({
    loading: false,
    data: [],
    suggestions: [],
    error: "",
  });
  const editadd = reactive({
    loading: false,
    data: [],
    addId: "",
    error: "",
  });

  const st = reactive({
    loading: false,
    data: [],
    error: "",
  });

  const zipcode = reactive({
    loading: false,
    data: [],
    error: "",
  });
  const invoice = reactive({
    loading: false,
    data: [],
    error: "",
  });

  const packingList = reactive({
    loading: false,
    data: [],
    error: "",
  });

  const orderdetails = reactive({
    loading: false,
    data: [],
    error: "",
  });

  const route = useRoute();
  const getAddress = async () => {
    addresses.data = [];
    addresses.loading = true;
    axios.authApi
      .get("/addresses")
      .then((resp) => {
        if (resp.status) {
          store.dispatch("setAddresses", resp.data.data);
          addresses.data = resp.data.data;
        } else {
          addresses.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (addresses.loading = false));
  };
  const getOrders = (pg = 1, qry) => {
    orders.loading = true;
    axios.authApi
      .get("/account/orders", {
        params: {
          page: pg,
          q: qry,
        },
      })
      .then((resp) => {
        if (resp.status) {
          orders.data = resp.data.data;
        } else {
          orders.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (orders.loading = false));
  };

  const addAddress = (label, address = []) => {
    axios.authApi
      .post("/account/create-address", {
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        nickname: address.nickname,
        state: typeof(address.state) == 'string' ? address.state : address.state.state,
        zip_code: typeof(address.zip_code) ? address.zip_code : address.zip_code.zip_code,
        label: label ? label : address.label ? address.label.shipping : null,
      })
      .then((resp) => {
        if (resp.status) {
          address.data = resp.data;
          address.address_id = address.data.data.address.id;
          address.licenses = address.data.data.licenses;
          getAddress();
        } else {
          address.error = resp.data.error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const editAddress = async (id) => {
    await axios.authApi
      .get("/account/edit-address/" + id)
      .then((resp) => {
        if (resp.status) {
          editadd.data = resp.data.data;
          editadd.addId = editadd.data.address.id;
        } else {
          editadd.error = resp.data.error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteAddress = (id) => {
    axios.authApi
      .get("/account/delete-address/" + id)
      .then((resp) => {
        if (resp.status) {
          address.data = resp.data.data;
          getAddress();
        } else {
          address.error = resp.data.error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getStates = () => {
    st.loading = true;
    axios.api
      .get("/get-states")
      .then((resp) => {
        if (resp.status) {
          st.data = resp.data.data;
        } else {
          st.error = resp.data.error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getZipcodes = (states, query) => {
    zipcode.loading = true;
    axios.authApi
      .get("/zip-codes", {
        params: {
          state: states,
          q: query,
        },
      })
      .then((resp) => {
        if (resp.status) {
          zipcode.data = resp.data.data;
        } else {
          zipcode.error = resp.data.error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUsers = async () => {
    users.loading = true;
    await axios.authApi
      .get("/account/users")
      .then((resp) => {
        if (resp.status) {
          users.data = resp.data.data;
          users.activeUser = resp.data.data.users.filter((r) => r.loggedIn)[0];
        } else {
          users.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (users.loading = false));
  };

  const getOrderdetails = () => {
    orderdetails.loading = true;
    let id = route.params.id;
    axios.authApi
      .get("/account/order-details/" + id)
      .then((resp) => {
        if (resp.status) {
          orderdetails.data = resp.data;
          if (flagFrom) {
            OrderCompleted(orderdetails.data.data, store.getters.cart);
          }
          orderdetails.loading = false;
        } else {
          orderdetails.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (orderdetails.loading = false));
  };
  const Invoice = () => {
    invoice.loading = true;
    let id = route.params.id;
    axios.api
      .get("/invoice/" + id)
      .then((resp) => {
        if (resp.status) {
          invoice.data = resp.data;
          invoice.loading = false;
        } else {
          invoice.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (invoice.loading = false));
  };

  const PackingList = () => {
    packingList.loading = true;
    let id = route.params.id;
    axios.authApi
      .get("/packing-list/" + id)
      .then((resp) => {
        if (resp.status) {
          packingList.data = resp.data;
        } else {
          packingList.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (packingList.loading = false));
  };

  const getReOrderDetails = () => {
    reOrderdetails.loading = true;
    let id = route.params.id;
    axios.authApi
      .get("/reorder/" + id)
      .then((resp) => {
        if (resp.status) {
          reOrderdetails.data = resp.data;
        } else {
          reOrderdetails.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (reOrderdetails.loading = false));
  };

  const queryAutocompleteForSuggestion = (query) => {
    const lookup = new SmartySDK.usAutocompletePro.Lookup(query);
    addresses.suggestions = [];

    autoCompleteClient
      .send(lookup)
      .then((response) => {
        response.result.forEach((suggestion) => {
          const secondary = suggestion.secondary && suggestion.secondary !== "#"
            ? ` ${suggestion.secondary}`
            : "";
          addresses.suggestions.push({
            suggestion: suggestion,
            address: suggestion.streetLine +
              secondary +
              " " +
              suggestion.city +
              ", " +
              suggestion.state +
              " " +
              suggestion.zipcode
          });
        });
      })
      .catch((e) => console.log(e.error));
  };

  return {
    invoice,
    Invoice,
    PackingList,
    packingList,
    editadd,
    addresses,
    getAddress,
    deleteAddress,
    editAddress,
    orders,
    getOrders,
    users,
    getUsers,
    orderdetails,
    getOrderdetails,
    address,
    addAddress,
    st,
    getStates,
    zipcode,
    getZipcodes,
    getReOrderDetails,
    reOrderdetails,
    queryAutocompleteForSuggestion,
  };
}
