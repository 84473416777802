<template>
  <div class="checkout-block">
    <div class="checkout-block-header" v-if="title">
      <div class="inline-flex items-center space-x-2">
        <Icon
          v-if="icon"
          :name="icon"
          class="w-5 h-5 text-theme-dark-shade -mt-0.5 opacity-80"
        />
        <div
          class="title inline-flex items-start gap-1 sm:gap-4 flex-col sm:flex-row sm:items-center"
          v-if="$slots.title"
        >
          <h3>{{ title || "Card Title" }}</h3>
          <slot name="title" />
        </div>
        <h3 v-else>{{ title || "Card Title" }}</h3>
      </div>
      <div class="checkout-block-action" v-if="$slots.action">
        <slot name="action" />
      </div>
    </div>
    <div class="checkout-block-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutBlock",
  props: {
    title: String,
    icon: String,
  },
};
</script>
<style lang="scss" scoped>
.checkout-block {
  @apply flex flex-col w-full bg-white rounded-sm shadow-box;

  .checkout-block-header {
    @apply flex flex-col sm:flex-row gap-2 sm:items-center sm:justify-between w-full px-5 py-3;
    border-bottom: 1px solid rgba(208, 230, 232, 0.31);
    h3 {
      @apply text-base text-theme-secondary font-semibold leading-none;
    }
    .checkout-block-action {
      @apply text-theme-body font-medium text-base;

      a {
        @apply text-theme-body;
      }
    }
  }
  .checkout-block-content {
    @apply h-full py-6 px-6;
  }
}
.modal {
  .checkout-block-header {
    @apply flex flex-row items-center justify-between;
  }
}
</style>
