<template>
  <Spinner :loading="orderdetails.loading" />
  <div
    v-if="!orderdetails.loading"
    class="checkout-page w-auto min-w-full pb-10 -m-3 sm:mx-0"
  >
    <div class="order-details">
      <Block>
        <div
          class="flex flex-col sm:flex-row gap-3 items-start sm:items-center justify-between pb-12"
        >
          <div class="flex items-center space-x-4">
            <svg
              class="w-8 h-8 sm:w-12 sm:h-12 flex-shrink-0"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="23"
                cy="23"
                r="23"
                fill="#75EEA5"
                fill-opacity="0.37"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.8203 13.4316C17.6352 13.4316 13.4316 17.6352 13.4316 22.8203C13.4316 28.0054 17.6352 32.209 22.8203 32.209C28.0054 32.209 32.209 28.0054 32.209 22.8203C32.209 17.6352 28.0054 13.4316 22.8203 13.4316ZM26.8899 21.2328C26.9648 21.1471 27.0219 21.0473 27.0577 20.9393C27.0935 20.8313 27.1073 20.7172 27.0984 20.6037C27.0894 20.4903 27.0579 20.3797 27.0056 20.2787C26.9533 20.1776 26.8813 20.088 26.7939 20.0151C26.7065 19.9422 26.6053 19.8876 26.4965 19.8544C26.3876 19.8212 26.2732 19.8101 26.16 19.8218C26.0468 19.8334 25.9371 19.8676 25.8373 19.9223C25.7375 19.977 25.6496 20.0511 25.5789 20.1403L21.9088 24.5436L20.0097 22.6436C19.8487 22.4882 19.6331 22.4021 19.4093 22.4041C19.1855 22.406 18.9715 22.4958 18.8132 22.654C18.655 22.8123 18.5652 23.0264 18.5633 23.2501C18.5613 23.4739 18.6473 23.6895 18.8028 23.8505L21.3634 26.4111C21.4472 26.4949 21.5476 26.5603 21.6582 26.6031C21.7687 26.646 21.887 26.6654 22.0054 26.66C22.1239 26.6546 22.2399 26.6246 22.3461 26.5719C22.4523 26.5192 22.5464 26.4449 22.6223 26.3539L26.8899 21.2328Z"
                fill="#3FD37A"
              />
            </svg>
            <div>
              <h3
                class="text-base sm:text-lg font-semibold text-theme-secondary"
              >
                Order Successfully Placed
              </h3>
              <p class="text-sm sm:text-md font-normal text-theme-body">
                We have received your order
              </p>
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <span
              class="ml-10 sm:ml-0 px-2 text-xxs sm:text-xs font-medium text-theme-secondary uppercase leading-none bg-theme-purple-dark bg-opacity-20 sm:px-6 py-1.5 rounded-full"
              >#ORDER NO
              {{
                orderdetails.data.data
                  ? orderdetails.data.data.order_number
                  : null
              }}</span
            >
            <span
              v-if="orderdetails.data?.data?.reference_number"
              class="ml-10 sm:ml-0 px-2 text-xxs sm:text-xs font-medium text-theme-secondary uppercase leading-none bg-theme-purple-dark bg-opacity-20 sm:px-6 py-1.5 rounded-full"
              >#REF NO
              {{ orderdetails.data?.data?.reference_number || "" }}</span
            >
          </div>
        </div>
        <div
          class="flex flex-col sm:flex-row items-stretch -mx-8 -mt-6 mb-4 border-t border-b border-theme-border border-opacity-40"
        >
          <!-- Order Details -->
          <div
            class="flex-shrink-0 flex flex-col justify-start w-full sm:w-80 border-b sm:border-b-0 sm:border-r border-theme-border border-opacity-40"
          >
            <dl
              class="text-base font-light text-theme-body-light space-y-3 px-8 pt-6 mb-8"
            >
              <div class="flex items-center justify-between">
                <dt>Order Total</dt>
                <dd>
                  ${{
                    commaFormat(
                      getTotal(
                        orderdetails.data.data
                          ? orderdetails.data.data.order_line_items
                          : null
                      ).toFixed(2)
                    )
                  }}
                </dd>
              </div>

              <div class="flex items-center justify-between">
                <dt class="address-title">Shipping</dt>
                <dd>
                  ${{
                    commaFormat(
                      Number(
                        orderdetails.data.data
                          ? orderdetails.data.data.shippingTotal
                          : null
                      ).toFixed(2)
                    )
                  }}
                </dd>
              </div>

              <div
                v-if="orderdetails.data.data.taxes"
                class="flex items-center justify-between"
              >
                <dt>Taxes</dt>
                <dd>
                  ${{
                    commaFormat(
                      Number(
                        orderdetails.data.data
                          ? orderdetails.data.data.taxes
                          : null
                      ).toFixed(2)
                    )
                  }}
                </dd>
              </div>

              <div
                class="flex items-center justify-between"
                v-for="discount in orderdetails.data.data
                  ? orderdetails.data.data.discounts
                  : null"
                :key="discount.id"
              >
                <dt>{{ discount.label }}</dt>
                <dd>
                  -${{
                    commaFormat(
                      Number(
                        discount.price.toString().replace("-", "")
                      ).toFixed(2)
                    )
                  }}
                </dd>
              </div>

              <div
                class="flex items-center  font-semibold justify-between text-theme-secondary"
              >
                <dt>Total</dt>
                <!-- <dd>${{ commaFormat(orderdetails.data.data ? (orderdetails.data.data.total).toFixed(2) : null) }}</dd> -->
                <dd>
                  ${{
                    commaFormat(
                      Number(
                        orderdetails.data.data
                          ? orderdetails.data.data.orderTotal
                          : null
                      ).toFixed(2)
                    )
                  }}
                </dd>
              </div>
            </dl>
          </div>
          <!-- Billing/Shipping Details -->
          <div class="billing-info">
            <dl>
              <div>
                <dt class="address-title">
                  Ship to
                </dt>
                <dd class="mt-2">
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.shipping_address.nickname
                        ? orderdetails.data.data.shipping_address.nickname
                        : orderdetails.data.data.shipping_address.company_name
                      : null
                  }}</span>
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.shipping_address.address1
                      : null
                  }}</span>
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.shipping_address.address2
                      : null
                  }}</span>
                  <span class="address-info"
                    >{{
                      orderdetails.data.data
                        ? orderdetails.data.data.shipping_address.city
                        : null
                    }},
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.shipping_address.state
                        : null
                    }}
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.shipping_address.zip_code
                        : null
                    }}</span
                  >
                </dd>
              </div>
              <div>
                <dt class="address-title">
                  Bill to
                </dt>
                <dd class="mt-2">
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.billing_address.company_name
                      : null
                  }}</span>
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.billing_address.address1
                      : null
                  }}</span>
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.billing_address.address2
                      : null
                  }}</span>
                  <span class="address-info">
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.billing_address.city
                        : null
                    }},
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.billing_address.state
                        : null
                    }}
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.billing_address.zip_code
                        : null
                    }}</span
                  >
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div class="w-full flex items-center justify-start space-x-8">
          <div
            class="flex items-start space-x-3"
            v-for="shipment in orderdetails.data.shipments"
            :key="shipment.id"
          >
            <div class="flex items-start space-x-3">
              <div class="flex items-center space-x-3">
                <div class="block flex-shrink-0 w-12">
                  <img
                    :src="
                      shipment.slug == 'fedex'
                        ? shipment.slug == 'usps'
                          ? shipment.slug == 'ups'
                            ? null
                            : '/images/ups.png'
                          : '/images/fedex.png'
                        : '/images/usps.png'
                    "
                    alt="Visa"
                    class="max-w-full"
                  />
                </div>
                <p class="text-base text-theme-body font-medium">
                  <span
                    v-if="shipment.expected_delivery"
                    class="text-theme-secondary pr-2"
                    >Est Delivery</span
                  >{{
                    shipment.expected_delivery
                      ? shipment.expected_delivery.slice(0, 10)
                      : null
                  }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col sm:flex-row items-start gap-3"
            v-for="(cardnumber, i) in orderdetails.data.data
              ? orderdetails.data.data.payments
              : null"
            :key="cardnumber.id"
          >
            <span
              class="flex-shrink-0 text-sm uppercase text-theme-body-light font-medium"
            >
              {{
                cardnumber.process_data
                  ? cardnumber.process_data.billing
                    ? i == 0
                      ? "Paid Via"
                      : "And"
                    : `paid via card ${++i}`
                  : null
              }}
            </span>
            <div class="flex items-center space-x-3">
              <div class="flex items-center space-x-3">
                <div
                  v-if="
                    cardnumber.process_data
                      ? cardnumber.process_data.billing
                        ? true
                        : false
                      : false
                  "
                  class="block flex-shrink-0 w-8"
                >
                  <img
                    :src="
                      cardnumber.process_data
                        ? cardnumber.process_data.billing
                          ? cardnumber.process_data.billing[
                              'cc-number'
                            ].substring(0, 1) == 4
                            ? '/images/card-providers/visa.png'
                            : cardnumber.process_data.billing[
                                'cc-number'
                              ].substring(0, 1) == 5
                            ? '/images/card-providers/master-card.png'
                            : cardnumber.process_data.billing[
                                'cc-number'
                              ].substring(0, 1) == 3
                            ? '/images/card-providers/american-express.png'
                            : cardnumber.process_data.billing[
                                'cc-number'
                              ].substring(0, 1) == 7
                            ? '/images/card-providers/american-express.png'
                            : cardnumber.process_data.billing[
                                'cc-number'
                              ].substring(0, 1) == 6
                            ? '/images/card-providers/Discover-logo.png'
                            : ''
                          : null
                        : null
                    "
                    alt="Visa"
                    class="max-w-full"
                  />
                </div>
                <p
                  v-if="
                    cardnumber.process_data
                      ? cardnumber.process_data.billing
                        ? true
                        : false
                      : false
                  "
                  class="text-sm text-theme-body"
                >
                  {{
                    cardnumber.process_data
                      ? cardnumber.process_data.billing
                        ? cardnumber.process_data.billing[
                            "cc-number"
                          ].substring(0, 1) == 3
                          ? "XXXX-XXXXXX-X" +
                            cardnumber.process_data.billing["cc-number"].slice(
                              -4
                            )
                          : "XXXX-XXXX-XXXX-" +
                            cardnumber.process_data.billing["cc-number"].slice(
                              -4
                            )
                        : null
                      : null
                  }}
                </p>
                <p v-else class="text-sm text-theme-body">
                  {{ cardnumber.process_data ? "$" + cardnumber.amount : null }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="flex items-start space-x-3"
            v-if="
              orderdetails.data.data?.payment_term != null &&
                orderdetails.data.data?.payments?.length == 0
            "
          >
            <span
              class="flex-shrink-0 text-sm uppercase text-theme-body-light font-medium"
            >
              Payment Method
            </span>
            <div class="flex items-center space-x-3">
              <div class="flex items-center space-x-3">
                <div class="block flex-shrink-0 w-8">
                  <img
                    :src="
                      orderdetails.data.data?.payment_term == 'Bank Transfer'
                        ? '/images/card-providers/bank.jpg'
                        : orderdetails.data.data?.payment_term == 'Zelle'
                        ? '/images/card-providers/zelle.png'
                        : '/images/card-providers/medusa.png'
                    "
                    alt="Visa"
                    class="max-w-full"
                  />
                </div>
                <p class="text-sm text-theme-body">
                  {{ orderdetails.data.data?.payment_term }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="instructions" v-if="orderdetails.data.data?.payment_term == 'Bank Transfer' || orderdetails.data.data?.payment_term == 'Zelle'">
          <Icon name="info" />
          <p>
            You selected {{ orderdetails.data.data?.payment_term }} as your payment method, please
            <a target="_blank" :href="orderdetails.data.data?.pdf">click here</a> to see the instructions for how to make
            the payment
          </p>
        </div>

        <!-- Buttons -->
        <div class="w-full flex items-center justify-start gap-3 mt-10">
          <router-link
            v-for="Inv in orderdetails.data.data.invoices"
            :key="Inv.id"
            target="_blank"
            :to="'/invoice/' + Inv.id"
            class="download-btn"
          >
            <Icon
              name="document-file"
              class="w-3.5 h-3.5 sm:w-4 sm:h-4 -mt-0.5"
            />
            <span>View Invoice</span>
          </router-link>
          <router-link to="/home" class="download-btn">
            <Icon name="left-arrow" class="w-4 h-4 sm:w-5 sm:h-5 -my-1" />
            <span class="pr-2">Continue Shopping</span>
          </router-link>
        </div>
      </Block>
    </div>
    <div class="order-summary">
      <Block icon="package-filled" title="Order Summary">
        <div class="-mx-5 -mt-2">
          <OrderItems
            :items="
              sortItems(
                orderdetails.data.data
                  ? orderdetails.data.data.order_line_items
                  : null
              )
            "
          />
        </div>
      </Block>
    </div>

    <!-- Address modal -->
    <Modal :show="showAddressModal" @close="showAddressModal = false">
      <Block icon="plus" title="New Address">
        <template #action>
          <button
            class="text-sm font-medium text-theme-indigo-light inline-flex items-center uppercase tracking-wider"
            @click="showAddressModal = false"
          >
            close
          </button>
        </template>
        <div>
          <form class="grid grid-cols-2 gap-8" action="#">
            <div>
              <Input
                label="First Name"
                v-model:modelValue="newAddress.fname"
                placeholder="Enter your first name"
              />
            </div>
            <div>
              <Input
                label="Last Name"
                v-model:modelValue="newAddress.lname"
                placeholder="Enter your last name"
              />
            </div>
            <div class="col-span-2">
              <Input
                label="Address Line 1"
                v-model:modelValue="newAddress.line1"
                placeholder="Address Line 1"
              />
            </div>
            <div class="col-span-2">
              <Input
                label="Address Line 2"
                v-model:modelValue="newAddress.line2"
                placeholder="Address Line 2"
              />
            </div>
            <div class="col-span-2 flex justify-end">
              <div class="w-56 flex-shrink-0">
                <Button title="Save Address" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </Block>
    </Modal>

    <!-- payments modal -->
    <Modal :show="showPaymentsModal" @close="showPaymentsModal = false">
      <Block icon="plus" title="New Payment">
        <template #action>
          <button
            class="text-sm font-medium text-theme-indigo-light inline-flex items-center uppercase tracking-wider"
            @click="showPaymentsModal = false"
          >
            close
          </button>
        </template>
        <div>
          <!-- Payment Info/Form here -->
          <h3>Payment Form here</h3>
        </div>
      </Block>
    </Modal>

    <!-- Split Payments-->
    <Modal :show="showSplitPayModal" @close="showSplitPayModal = false">
      <div class="w-full rounded-lg bg-white shadow-box">
        <div class="flex justify-between px-5 py-3 w-full">
          <div class="flex items-start justify-start space-x-3">
            <Icon name="split" class="w-7 h-7 mt-1 text-theme-purple-dark" />
            <div class="text-left text-base">
              <h3 class="text-lg text-theme-secondary font-semibold">
                Split your Payment
              </h3>
              <p class="text-theme-body font-medium">
                Use 2 cards to split the bill
              </p>
            </div>
          </div>
          <button
            class="text-theme-secondary flex items-center justify-center mr-2 text-base font-semibold"
            v-if="currentTab === 1"
            @click="changeStep(0)"
          >
            <Icon name="left-arrow" class="w-5 h-5 mr-0.5" />
            Back
          </button>
        </div>
        <div class="w-full">
          <div class="grid grid-cols-2">
            <div
              :class="
                currentTab === 0
                  ? 'border-b-2 border-theme-body-light'
                  : 'border-b border-theme-border'
              "
            ></div>
            <div
              :class="
                currentTab === 1
                  ? 'border-b-2 border-theme-body-light'
                  : 'border-b border-theme-border'
              "
            ></div>
          </div>
          <div class="text-left">
            <div class="block w-full py-5 px-8" v-show="currentTab === 0">
              <div class="flex flex-col items-start">
                <span
                  class="rounded-full bg-theme-bg-shade text-xs text-theme-secondary py-0.5 px-4"
                  >STEP 1</span
                >
                <div class="flex items-center justify-between w-full py-2">
                  <h3 class="text-base font-semibold text-theme-secondary">
                    Pick the first card & Enter amount.
                  </h3>
                  <span
                    class="text-base uppercase text-theme-body-light font-semibold"
                    >bill Total : $10,000</span
                  >
                </div>
                <div class="w-full py-6">
                  <RadioGroup v-model="selectCard1" class="w-full">
                    <RadioGroupLabel class="sr-only"
                      >Payment Method</RadioGroupLabel
                    >
                    <div class="grid grid-cols-3 gap-6">
                      <RadioGroupOption
                        as="template"
                        v-for="option in paymentOptions"
                        :key="option.name"
                        :value="option"
                        v-slot="{ checked }"
                      >
                        <div
                          class="relative flex flex-col rounded-lg border border-theme-border bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none select-none"
                        >
                          <div class="flex w-full justify-end -mb-2">
                            <div
                              :class="[
                                checked
                                  ? ' bg-theme-indigo border-theme-indigo text-white'
                                  : 'bg-white border-theme-border ',
                                'border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center',
                              ]"
                            >
                              <svg
                                v-if="checked"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="block">
                            <RadioGroupLabel
                              as="p"
                              class="text-base font-medium text-theme-secondary mb-1"
                              >{{ option.name }}</RadioGroupLabel
                            >
                            <RadioGroupDescription
                              as="div"
                              class="block text-xs text-theme-dark w-4/5 mb-3"
                              >{{ option.number }}</RadioGroupDescription
                            >
                            <div class="flex items-center justify-between">
                              <RadioGroupDescription
                                as="span"
                                class=" text-theme-dark text-sm font-normal"
                                >{{ option.expiry }}</RadioGroupDescription
                              >
                              <div
                                class="w-auto h-7 flex items-center justify-end"
                              >
                                <img
                                  :src="option.provider"
                                  :alt="option.name"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            :class="[
                              checked
                                ? 'border-theme-indigo border-opacity-50'
                                : 'border-transparent',
                              'absolute -inset-px rounded-lg border-2 pointer-events-none',
                            ]"
                            aria-hidden="true"
                          />
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                </div>
                <div class="flex items-center space-x-4">
                  <input
                    type="number"
                    class="bg-theme-bg rounded-md border border-theme-border border-opacity-50 px-4 py-2.5 leading-none placeholder-theme-bg-shade w-60 focus:border-theme-body-light focus:ring-0"
                    placeholder="Enter Amount"
                  />
                  <button
                    class="bg-theme-bg rounded-md border border-theme-border border-opacity-50 text-sm text-theme-secondary text-opacity-90 py-1.5 px-2"
                  >
                    25%
                  </button>
                  <button
                    class="bg-theme-bg rounded-md border border-theme-border border-opacity-50 text-sm text-theme-secondary text-opacity-90 py-1.5 px-2"
                  >
                    50%
                  </button>
                  <button
                    class="bg-theme-bg rounded-md border border-theme-border border-opacity-50 text-sm text-theme-secondary text-opacity-90 py-1.5 px-2"
                  >
                    75%
                  </button>
                  <button
                    class="bg-theme-bg rounded-md border border-theme-border border-opacity-50 text-sm text-theme-secondary text-opacity-90 py-1.5 px-2"
                  >
                    100%
                  </button>
                </div>
                <div
                  class="flex w-full items-center justify-between mt-10 py-2"
                >
                  <p class="text-lg text-theme-secondary font-semibold">
                    $4000
                    <span class="opacity-70">will be charged to this card</span>
                  </p>
                  <button
                    class="bg-theme-body bg-opacity-40 hover:bg-opacity-50 w-60 font-semibold text-center py-2.5 rounded-md"
                    @click="changeStep(1)"
                  >
                    Next Card
                  </button>
                </div>
              </div>
            </div>
            <div class="block w-full py-5 px-8" v-show="currentTab === 1">
              <div class="flex flex-col items-start">
                <span
                  class="rounded-full bg-theme-bg-shade text-xs text-theme-secondary py-0.5 px-4"
                  >STEP 2</span
                >
                <div class="flex items-center justify-between w-full py-2">
                  <h3 class="text-base font-semibold text-theme-secondary">
                    Choose card to bill $6000
                  </h3>
                  <span
                    class="text-base uppercase text-theme-body-light font-semibold"
                    >bill Total : $10,000</span
                  >
                </div>
                <div class="w-full py-6">
                  <RadioGroup v-model="selectCard1" class="w-full">
                    <RadioGroupLabel class="sr-only"
                      >Payment Method</RadioGroupLabel
                    >
                    <div class="grid grid-cols-3 gap-6">
                      <RadioGroupOption
                        as="template"
                        v-for="option in paymentOptions"
                        :key="option.name"
                        :value="option"
                        v-slot="{ checked }"
                      >
                        <div
                          class="relative flex flex-col rounded-lg border border-theme-border bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none select-none"
                        >
                          <div class="flex w-full justify-end -mb-2">
                            <div
                              :class="[
                                checked
                                  ? ' bg-theme-indigo border-theme-indigo text-white'
                                  : 'bg-white border-theme-border ',
                                'border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center',
                              ]"
                            >
                              <svg
                                v-if="checked"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="block">
                            <RadioGroupLabel
                              as="p"
                              class="text-base font-medium text-theme-secondary mb-1"
                              >{{ option.name }}</RadioGroupLabel
                            >
                            <RadioGroupDescription
                              as="div"
                              class="block text-xs text-theme-dark w-4/5 mb-3"
                              >{{ option.number }}</RadioGroupDescription
                            >
                            <div class="flex items-center justify-between">
                              <RadioGroupDescription
                                as="span"
                                class=" text-theme-dark text-sm font-normal"
                                >{{ option.expiry }}</RadioGroupDescription
                              >
                              <div
                                class="w-auto h-7 flex items-center justify-end"
                              >
                                <img
                                  :src="option.provider"
                                  :alt="option.name"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            :class="[
                              checked
                                ? 'border-theme-indigo border-opacity-50'
                                : 'border-transparent',
                              'absolute -inset-px rounded-lg border-2 pointer-events-none',
                            ]"
                            aria-hidden="true"
                          />
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                </div>

                <div
                  class="flex w-full items-center justify-between mt-10 py-2"
                >
                  <p class="text-lg text-theme-secondary font-semibold">
                    $6000
                    <span class="opacity-70">will be charged to this card</span>
                  </p>
                  <button
                    class="bg-theme-secondary hover:bg-opacity-90 w-60 font-semibold text-center py-2.5 rounded-md text-white"
                    @click="finishSelection"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Block from "./modules/Block.vue";
import { onMounted, ref } from "vue";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import Input from "@/components/Input.vue";
import Account from "@/modules/Account";
import AccountSummary from "@/modules/AccountSummary";
import Mymixin from "@/modules/mixins";
import { mapActions } from "vuex";
import OrderItems from "./modules/OrderItems.vue";

const address = [
  {
    name: "Main Store",
    address: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
    license: "LICENSE #24SDSDSDSDS",
    isValid: true,
  },
  {
    name: "New York",
    address: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
    license: "LICENSE #24SDSDSDSDS",
    isValid: true,
  },
  {
    name: "Main Store",
    address: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
    license: "LICENSE #24SDSDSDSDS",
    isValid: false,
  },
];

const paymentOptions = [
  {
    name: "Danish Iqbal",
    number: "XXXX-XXXX-XXXX-4059",
    expiry: "EXP 10/23",
    provider: "/images/card-providers/master-card.png",
  },
  {
    name: "Danish Iqbal2",
    number: "XXXX-XXXX-XXXX-4054",
    expiry: "EXP 10/23",
    provider: "/images/card-providers/visa.png",
  },
  {
    name: "Paylater",
    number: "Powered by Behalf",
    expiry: "",
    provider: "/images/card-providers/behalf.png",
  },
];

export default {
  name: "thankYou",
  components: {
    Block,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    Input,
    OrderItems,
  },
  mixins: [Mymixin],
  data() {
    return {
      loading: false,
      inter: null,
      thank_you_page: false,
    };
  },
  methods: {
    trackingLink(data) {
      window.open(data);
    },
    sortItems(data) {
      return data
        ? data.sort((a, b) => a.productName.localeCompare(b.productName))
        : [];
    },
    getTotal(data) {
      let temp = 0;
      if (data)
        data.forEach((element) => (temp += element.qty * element.singlePrice));
      return temp;
    },

    getOrderNumber() {
      // this.inter = setInterval(() => {
      //   if (this.orderdetails.data?.data?.order_number == null) {
      this.getOrderdetails();
      //   } else {
      //     this.loading = true;
      //     this.stopGetOrderNumber();
      //   }
      // }, 1000);
    },
    stopGetOrderNumber() {
      //  this.axiosAuth.get('/orderReceived/' + this.$route.params.id).then((res) => {
      //      res.data
      //  })
      clearInterval(this.inter);
    },
  },
  created() {
    this.getOrderNumber();
    if (this.currentRouteName == "Thankyou") {
      this.thank_you_page = true;
    }
  },
  // beforeMount() {
  //   this.getOrderdetails();
  // },
  mounted() {
    this.$store.dispatch("getCart", { ax: null });
  },
  beforeUnmount() {
    clearInterval(this.inter);
  },
  computed: {
    ...mapActions(["getCart"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  setup() {
    const selectCard1 = ref(paymentOptions[0]);
    const selectCard2 = ref(paymentOptions[0]);
    const showAddressModal = ref(false);
    const showPaymentsModal = ref(false);
    const showSplitPayModal = ref(false);
    let currentTab = ref(0);
    const newAddress = {
      fname: "",
      lname: "",
      line1: "",
      line2: "",
    };

    const changeStep = (step) => {
      currentTab.value = step;
    };

    const finishSelection = () => {
      currentTab.value = 0;
      showSplitPayModal.value = false;
    };

    const { orderdetails, getOrderdetails } = Account("thankyou");
    // const {getCart} = Cart();
    const { coins } = AccountSummary();
    onMounted(() => {
      // getCart();
      coins();
    });

    return {
      orderdetails,
      address,
      paymentOptions,
      showAddressModal,
      showPaymentsModal,
      showSplitPayModal,
      newAddress,
      currentTab,
      selectCard1,
      selectCard2,
      getOrderdetails,
      changeStep,
      finishSelection,
    };
  },
};
</script>

<style lang="scss" scoped>
.download-btn {
  @apply w-auto inline-flex items-center text-xxs sm:text-sm text-white bg-theme-secondary hover:bg-theme-secondary hover:bg-opacity-95 space-x-0.5 sm:space-x-1 tracking-wide px-2 sm:px-3 py-2.5 rounded leading-none;
}
.checkout-page {
  @apply flex flex-col-reverse lg:flex-row items-start gap-6;

  .order-details {
    @apply flex-grow space-y-8 w-full lg:w-auto overflow-hidden;
  }

  .order-summary {
    @apply w-full lg:w-96 flex-shrink-0;
  }
}
table {
  @apply min-w-full divide-y divide-gray-200;
  width: 100%;
  word-break: break-word;
  th {
    @apply px-4 py-1.5 text-xxs font-medium text-brand-body uppercase tracking-wider;
  }
  tbody {
    @apply bg-white text-xs text-brand-secondary font-medium;

    td {
      @apply py-4 font-normal;

      &.hightlight {
        @apply font-semibold;
      }
    }
  }
  .order-item {
    @apply flex items-center space-x-3 flex-shrink-0;
    img {
      @apply w-8 h-8 mr-1 object-contain object-center;
    }
    .order-item-info {
      @apply flex-grow;
      p {
        @apply text-xs leading-none text-brand-secondary font-semibold mb-0.5;
      }
      span {
        @apply text-xxs leading-none text-brand-gray font-normal;
      }
    }
  }
}
.billing-info {
  @apply flex flex-col justify-center flex-grow;

  dl {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6 text-sm px-8 pt-6 mb-8 text-theme-body-light;

    dt {
      @apply block uppercase text-xs tracking-wider font-light;
    }
  }
  .address-title {
    @apply block text-md text-theme-secondary font-medium mb-0.5;
  }

  .address-info {
    @apply block text-sm text-theme-body font-medium;
  }
}

.instructions {
  @apply flex bg-theme-purple-dark bg-opacity-20 text-base font-light space-x-2 px-3 py-4 items-start rounded-md leading-snug mt-5;

  a {
    @apply font-semibold text-theme-secondary underline;
  }

  svg {
    @apply text-theme-purple-dark w-5 h-5 flex-shrink-0;
  }
}
</style>
