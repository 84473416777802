import { inject, reactive } from "vue";
import { store } from '../store';

export default function AccountSummary() {
    const axios = inject('axios');

    let summary = reactive({
        loading: false,
        coin_balance: '0',
        error: ''
    });

    const coins = async() => {
        if (axios.isLoggedIn) {
            axios.authApi.get("/account/summary")
                .then((response) => {
                    if (response.status) {
                        store.dispatch("setCoins", response.data.data)
                        summary.coin_balance = response.data.data.coins_balance;
                    } else {
                        summary.error = response.data.error;
                    }
                }).catch(error => {
                    console.log(error)
                }).finally(() => (summary.loading = false));
        }
    };

    let coin = reactive({
        loading: false,
        error: '',
        data: []
    });

    const medusaCoins = async() => {
        if (axios.isLoggedIn) {
            axios.authApi.get("/points")
                .then((response) => {
                    if (response.status) {
                        coin.data = response.data.data;
                    } else {
                        coin.error = response.data.error;
                    }
                }).catch(error => {
                    console.log(error)
                }).finally(() => (coin.loading = false));
        }
    };

    const address = reactive({
        loading: false,
        address: [],
        error: ''
    });
    const getAddresses = async() => {
        if (axios.isLoggedIn) {
            address.loading = true;
            axios.authApi.get('/addresses').then((resp) => {
                if (resp.status) {
                    store.dispatch("setAddresses", resp.data.data)
                    address.data = resp.data.data;

                } else {
                    address.error = resp.data.error;
                }
            }).catch(function(error) {
                console.log(error);
            }).finally(() => (address.loading = false));
        } else {}
    };
    const selectaddress = reactive({
        loading: false,
        data: [],
        error: ''
    });
    const selectAddress = async (id, item_ids) => {
        selectaddress.loading = true;
        await axios.authApi.get('/select-shipping-address',{
            params: {
                shipping_address_id: id,
                item_ids
            }
        }).then((resp) => {
            if (resp.status) {
                selectaddress.data = resp.data.data;
            } else {
                selectaddress.error = resp.data.error;
            }
        }).catch(function(error) {
            console.log(error);
        }).finally(() => (selectaddress.loading = false));
    };

    const checkLicenseBeforeChange = async (id) => {
        try {
            selectaddress.loading = true;
            const response = await axios.authApi.get('check-address', {
                params: {
                    shipping_address_id: id
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error selecting address:", error.message);
        }
    };

    return {
        summary,
        coins,
        address,
        getAddresses,
        selectAddress,
        coin,
        medusaCoins,
        checkLicenseBeforeChange
    }

}