import { inject, onMounted, onUnmounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { store } from "../store";
import Segments from "./Segments";
const { ProductListViewed, ProductListViewedNonLoggedIn } = Segments();

export default function Collections(limit = 0) {
  const axios = inject("axios");

  const collection = reactive({
    loading: false,
    data: [],
    limitData: [],
    error: "",
  });

  const getCollections = () => {
    collection.loading = true;
    axios.api
      .get("/collections")
      .then((resp) => {
        if (resp.status) {
          collection.data = resp.data.data;
          collection.limitData = resp.data.data.slice(0, limit);
        } else {
          collection.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (collection.loading = false));
  };

  const collectionData = reactive({
    loading: false,
    data: [],
    error: "",
    loadingScroll: false,
  });

  const route = useRoute();

  var page = 1;
  let lastPage = 0;
  let sort = "latest";
  let stock = null;
  let load = false;

  const getCollection = async (slug, end) => {
    let api = axios.isLoggedIn ? axios.authApi : axios.api;
    let uri = axios.isLoggedIn ? '/aCollection?collection_slug='+ slug : '/collection?collection_slug='+ slug;
    if(slug == 'clearance'){
      uri = axios.isLoggedIn ? '/clearance' : '/clearance-products';
    }

    const resp = await api
      .get(uri, {
        params: {
          page: page,
          stock: false,
          min: 0,
          max: 0
        },
    });
    if (resp.status) {
      if(axios.isLoggedIn)
        ProductListViewed(route, resp.data.data?.data?.slice(0,end), slug, 1, 'home');
      else
        ProductListViewedNonLoggedIn(route, resp.data.data?.data?.slice(0,end), slug, 1, 'home');
      
      return resp.data.data?.data;
    } else {
      console.log(resp.data.error);
    }
  }

  async function getData(load) {
    let data = {};
    collectionData[load] = true;
    if (axios.isLoggedIn) {
      await axios.authApi
        .get("/aCollection", {
          params: { collection_slug: route.params.slug, page, stock, sort },
        })
        .then((res) => {
          data = res.data.data;
        });
    } else {
      await axios.api
        .get("/collection", {
          params: { collection_slug: route.params.slug, page, stock, sort },
        })
        .then((res) => {
          data = res.data.data;
        });
    }
    collectionData[load] = false;
    return data;
  }

  const sortChange = async (value) => {
    page = 1;
    sort = value;
    collectionData.data = await getData("loading");
  };

  const stockChange = async (v) => {
    page = 1;
    stock = v;
    collectionData.data = await getData("loading");
  };

  const handleScroll = async () => {
    const itemsContainer = document.querySelector(".items-listing-container");
    if (!itemsContainer) {
      return;
    }

    const canLoadMore = page < lastPage && !load;
    const elemScrollHeight = itemsContainer.scrollHeight;
    const elemScrollLoadHeight = elemScrollHeight - elemScrollHeight * 0.5;
    const shouldLoadMore =
      window.pageYOffset + 200 > elemScrollLoadHeight ||
      elemScrollHeight < window.innerHeight ||
      window.pageYOffset + elemScrollLoadHeight < window.innerHeight;

    if (canLoadMore && shouldLoadMore && !products.isLoadingScrollData) {
      page++;
      const res = await getData(store.getters.getSelected, "loadingScroll");
      if (res) {
        collectionData.data.current_page = page;
        collectionData.data.data.push(...res.data);
      }
    }
  };
  // this will register the event when the component is mounted on the DOM
  onMounted(() => window.addEventListener("scroll", handleScroll));

  // We then unregister the listener when the component is removed from the DOM
  onUnmounted(() => window.removeEventListener("scroll", handleScroll));

  const banner = reactive({
    loading: false,
    data: [],
    name: "",
    image: "",
    error: "",
  });

  const getBanner = async () => {
    banner.loading = true;
    axios.api
      .get("/collection-details?collection_slug=" + route.params.slug)
      .then((resp) => {
        if (resp.status) {
          banner.data = resp.data.data;
        } else {
          banner.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (banner.loading = false));
  };

  return {
    getData,
    collection,
    getCollections,
    sortChange,
    stockChange,
    banner,
    getBanner,
    getCollection
  };
}
